import * as React from "react"
import { Link, HeadFC, navigate } from "gatsby"

const NotFoundPage = () => {
  
  React.useEffect(() => {
    navigate('/');
  }, []);

  return (
    <main>
      <h1>Stránka nenalezena</h1>      
      <Link to="/">Zpět na začátek</Link>.      
    </main>
  )
}

export default NotFoundPage

export const Head: HeadFC = () => <title>Stránka nenalezena</title>
